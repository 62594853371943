import {MenuButton} from "~/components/MenuButton"
import {NavMenu} from "~/components/NavMenu"
import {useCallback, useState} from "react"
import clsx from "clsx"
import {useToolbarContext} from "~/contexts/useToolbarContext"
import {useLocation} from "@remix-run/react"

export const NavToolbar = ({className, style = "default"}: {
  className?: string,
  style?: "default" | "flat" | "elevated"
}) => {

  const {pathname} = useLocation()

  const {toolbarContentMap} = useToolbarContext()

  const [showMenu, setShowMenu] = useState(false)

  const openMenu = useCallback(() => setShowMenu(true), [setShowMenu])
  const closeMenu = useCallback(() => setShowMenu(false), [setShowMenu])

  return (
    <>
      <div
        className={clsx(
          "flex-shrink-0 w-full h-toolbar flex items-center px-1 bg-white md:h-toolbar-md md:px-3",
          style === "elevated" ? "bg-white shadow-md"
            : style === "default" ? "border-b border-gray-300"
              : "",
          className
        )}
      >
        <MenuButton
          isMenuOpen={false}
          onClick={openMenu}
        />
        <div className="flex-grow h-full flex items-center overflow-hidden">
          {toolbarContentMap.get(pathname)?.content}
        </div>
      </div>
      <NavMenu
        showMenu={showMenu}
        closeMenu={closeMenu}
      />
    </>
  )
}