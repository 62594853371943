import {useFeaturedArticlesContext} from "~/contexts/useFeaturedArticlesContext"

export const useNavigationItems = () => {

  const {featuredArticles} = useFeaturedArticlesContext()

  return {
    navigationItems: [{
      key: "home",
      name: "About Me",
      url: "/",
      requiresAuth: false,
    },
      ...featuredArticles.map((article) => (
        {
          key: article.id,
          name: article.title,
          url: `/article/${article.slug}`,
          requiresAuth: false,
        }
      )),
      {
        key: "library",
        name: "Library",
        url: "/library",
        requiresAuth: false,
      }, {
        key: "recipes",
        name: "Recipes",
        url: "/recipes",
        requiresAuth: false,
      }, {
        key: "ai-studio",
        name: "AI Studio",
        url: "/ai",
        requiresAuth: true,
      }]
  }
}