import {AnimatePresence, motion} from "framer-motion"
import {useNavigationItems} from "~/hooks/useNavigationItems"

import {MenuButton} from "~/components/MenuButton"
import {NavigationLink} from "~/components/NavigationLink"

export const NavMenu = ({showMenu, closeMenu}: { showMenu: boolean, closeMenu: () => void }) => {

  const {navigationItems} = useNavigationItems()

  return (
    <AnimatePresence>
      {showMenu && (
        <>
          <motion.div
            className="fixed inset-0 flex z-nav-menu bg-black/20 overflow-hidden"
            initial={{opacity: 0}}
            animate={{opacity: 1, transition: {duration: 0.3, ease: "easeOut"}}}
            exit={{opacity: 0, transition: {duration: 0.3, ease: "easeIn"}}}
            onClick={closeMenu}
          />
          <motion.div
            className="fixed top-0 left-0 right-auto bottom-0 w-full flex z-nav-menu g-white overflow-hidden shadow-xl sm:w-[400px]"
            initial={{opacity: 1, translateX: "-100%"}}
            animate={{opacity: 1, translateX: 0, transition: {duration: 0.3, ease: "easeOut"}}}
            exit={{opacity: 1, translateX: "-100%", transition: {duration: 0.3, ease: "easeIn"}}}
          >
            <div className="w-full h-auto flex flex-col px-3 bg-white shadow-md z-nav-menu">
              <div className="w-full h-16 flex flex-row items-center">
                <MenuButton
                  isMenuOpen={true}
                  onClick={closeMenu}
                />
              </div>
              {
                navigationItems.map((item) => {
                  return (
                    <NavigationLink
                      key={item.key}
                      text={item.name}
                      to={item.url}
                      onClick={closeMenu}
                      requiresAuth={item.requiresAuth}
                    />
                  )
                })
              }
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  )
}

