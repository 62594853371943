import {useUserContext} from "~/contexts/useUserContext"
import {NavLink} from "@remix-run/react"
import clsx from "clsx"
import {LockedIcon} from "~/components/icons/LockedIcon"

export const NavigationLink = ({
  text,
  to,
  requiresAuth,
  onClick
}: {
  text: string,
  to: string,
  requiresAuth?: boolean,
  onClick?: () => void
}) => {

  const {isLoggedIn} = useUserContext()

  return (
    <NavLink
      className={({isActive}) => clsx(
        "w-full flex items-center p-3 text-lg rounded-lg",
        isActive ? "font-semibold" : "hover:font-medium hover:bg-gray-50"
      )}
      to={to}
      onClick={onClick}
    >
      {text}
      {requiresAuth && !isLoggedIn && (
        <LockedIcon className="ml-auto flex-shrink-0 h-4 w-4"/>
      )}
    </NavLink>
  )
}